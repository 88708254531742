/**
 * Renders a single column section that contains a section heading,
 * textblock and an image displayed below
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading2, Heading3 } from '../../atoms/Headings/Headings';
import sanityImage from '../../../utils/sanityImage';
import Section from '../../molecules/Section/Section';

const CustomerStatsSection = ({
  backgroundImage,
  className,
  headingText,
  sectionStyles,
  subheadingText,
  textBlock,
}) => {
  const sectionCss = css`
    width: 100%;
  `;

  const textBlockCss = css`
    max-width: ${uc('620px')};
    margin: 0 auto ${uc('40px')};
  `;

  const sectionBackgroundImage = sanityImage(backgroundImage)
    .width(1800)
    .fit('max')
    .auto('format')
    .url();

  const backgroundImageCss = css`
    position: relative;
    height: ${uc('660px')};
    overflow: hidden;
    background: no-repeat top center;
    background-image: url(${sectionBackgroundImage});
    background-size: contain;

    @media (${breakpoints.tablet}) {
      height: ${uc('280px')};
    }
  `;

  const textSectionCss = css`
    align-items: center;
    margin: ${uc('110px auto')};
    text-align: center;

    @media (${breakpoints.tablet}) {
      margin: ${uc('55px auto')};
    }
  `;

  const headingCss = css`
    height: ${uc('150px')};
    margin: ${uc('0 -40px 30px 0')};
    color: ${colors.primary};
    font-size: ${uc('160px')};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};
    letter-spacing: 0;

    @media (${breakpoints.tablet}) {
      height: ${uc('100px')};
      margin-bottom: ${uc('10px')};
      font-size: ${uc('85px')};
      white-space: normal;
    }

    @media (${breakpoints.mobile}) {
      height: ${uc('80px')};
      font-size: ${uc('75px')};
    }
  `;

  const subheadingCss = css`
    padding: ${uc('20px 0')};
    color: ${colors.primary};
    font-size: ${uc('50px')};
    font-family: ${fontFamilies.proximaSoft};
    letter-spacing: 0;

    @media (${breakpoints.tablet}) {
      padding: 0;
      font-size: ${uc('25px')};
      white-space: normal;
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.twentyEight};
    }
  `;

  return (
    <Section
      sectionStyles={sectionStyles}
      css={sectionCss}
      className={className}
    >
      {textBlock && (textBlock.headingText || textBlock.description) && (
        <TextBlock css={textBlockCss} {...textBlock} />
      )}
      <div css={backgroundImageCss}>
        <div css={textSectionCss}>
          {headingText.length > 0 && (
            <Heading2 css={headingCss}> {headingText} </Heading2>
          )}
          {subheadingText.length > 0 && (
            <Heading3 css={subheadingCss}>{subheadingText}</Heading3>
          )}
        </div>
      </div>
    </Section>
  );
};

CustomerStatsSection.propTypes = {
  backgroundImage: PropTypes.shape({}),
  className: PropTypes.string,
  headingText: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  subheadingText: PropTypes.string,
  textBlock: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

CustomerStatsSection.defaultProps = {
  backgroundImage: {},
  className: '',
  headingText: '',
  sectionStyles: {},
  subheadingText: '',
  textBlock: [],
};

export default CustomerStatsSection;
